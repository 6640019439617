/**
 * Created by 芋道源码
 *
 * 数据字典工具类
 */
import store from '@/store'

export const DICT_TYPE = {
  USER_TYPE: 'user_type',
  COMMON_STATUS: 'common_status',

  // ========== SYSTEM 模块 ==========
  SYSTEM_USER_SEX: 'system_user_sex',
  SYSTEM_MENU_TYPE: 'system_menu_type',
  SYSTEM_ROLE_TYPE: 'system_role_type',
  SYSTEM_DATA_SCOPE: 'system_data_scope',
  SYSTEM_NOTICE_TYPE: 'system_notice_type',
  SYSTEM_OPERATE_TYPE: 'system_operate_type',
  SYSTEM_LOGIN_TYPE: 'system_login_type',
  SYSTEM_LOGIN_RESULT: 'system_login_result',
  SYSTEM_SMS_CHANNEL_CODE: 'system_sms_channel_code',
  SYSTEM_SMS_TEMPLATE_TYPE: 'system_sms_template_type',
  SYSTEM_SMS_SEND_STATUS: 'system_sms_send_status',
  SYSTEM_SMS_RECEIVE_STATUS: 'system_sms_receive_status',
  SYSTEM_ERROR_CODE_TYPE: 'system_error_code_type',
  SYSTEM_OAUTH2_GRANT_TYPE: 'system_oauth2_grant_type',

  // ========== INFRA 模块 ==========
  INFRA_BOOLEAN_STRING: 'infra_boolean_string',
  INFRA_REDIS_TIMEOUT_TYPE: 'infra_redis_timeout_type',
  INFRA_JOB_STATUS: 'infra_job_status',
  INFRA_JOB_LOG_STATUS: 'infra_job_log_status',
  INFRA_API_ERROR_LOG_PROCESS_STATUS: 'infra_api_error_log_process_status',
  INFRA_CONFIG_TYPE: 'infra_config_type',
  INFRA_CODEGEN_TEMPLATE_TYPE: 'infra_codegen_template_type',
  INFRA_CODEGEN_SCENE: 'infra_codegen_scene',
  INFRA_FILE_STORAGE: 'infra_file_storage',

  // ========== BPM 模块 ==========
  BPM_MODEL_CATEGORY: 'bpm_model_category',
  BPM_MODEL_FORM_TYPE: 'bpm_model_form_type',
  BPM_TASK_ASSIGN_RULE_TYPE: 'bpm_task_assign_rule_type',
  BPM_PROCESS_INSTANCE_STATUS: 'bpm_process_instance_status',
  BPM_PROCESS_INSTANCE_RESULT: 'bpm_process_instance_result',
  BPM_TASK_ASSIGN_SCRIPT: 'bpm_task_assign_script',
  BPM_OA_LEAVE_TYPE: 'bpm_oa_leave_type',

  // ========== PAY 模块 ==========
  PAY_CHANNEL_WECHAT_VERSION: 'pay_channel_wechat_version', // 微信渠道版本
  PAY_CHANNEL_ALIPAY_SIGN_TYPE: 'pay_channel_alipay_sign_type', // 支付渠道支付宝算法类型
  PAY_CHANNEL_ALIPAY_MODE: 'pay_channel_alipay_mode', // 支付宝公钥类型
  PAY_CHANNEL_ALIPAY_SERVER_TYPE: 'pay_channel_alipay_server_type', // 支付宝网关地址
  PAY_CHANNEL_CODE_TYPE: 'pay_channel_code_type', // 支付渠道编码类型
  PAY_ORDER_NOTIFY_STATUS: 'pay_order_notify_status', // 商户支付订单回调状态
  PAY_ORDER_STATUS: 'pay_order_status', // 商户支付订单状态
  PAY_ORDER_REFUND_STATUS: 'pay_order_refund_status', // 商户支付订单退款状态
  PAY_REFUND_ORDER_STATUS: 'pay_refund_order_status', // 退款订单状态
  PAY_REFUND_ORDER_TYPE: 'pay_refund_order_type', // 退款订单类别

  // ========== 追溯 模块 ==========
  TRACE_INFO_NORM:'trace_info_norm',
  TRACE_ZZGL_PLANTING:'trace_zzgl_planting',
  TRACE_ZGGL_TISSUECULTRUE:'trace_zzgl_tissueculture',
  TRACE_JGGL_CJG:'trace_jggl_cjg',
  TRACE_JGGL_SJG:'trace_jggl_sjg',
  TRACE_INFO_SC:'trace_info_sc',//生产方式
  TRACE_INFO_PLANT:'trace_info_plant',//操作方式
  TRACE_CG_XINXI:'trace_cg_xinxi',//采购信息方式
  TRACE_CG_MX:'trace_cg_mx',//待入库数据
  TRACE_INFO_SEED:"trace_info_seed",//种子购买方式
  TRACE_RECOVERY_METHOD: 'trace_recovery_method', // 采收方式
  INFO_MATERIAL_TYPE: 'info_material_type', // 物料种类
  INFO_MATERIAL_CATEGORY: 'info_material_category', // 物料分类
  ZS_AUTH_TYPE: 'info_auth_type', // 认证类型
  INFO_MERCHANTS_NATURE: 'info_merchants_nature',//企业性质
  INFO_MERCHANTS_CATEGORY: 'info_merchants_category',//客商分类
  INFO_SUPPLIER_CATEGORY: 'info_supplier_category',//供应商分类
  INFO_STOREHOUSE_TYPE: 'info_storehouse_type',//仓库类型
  INFO_RESERVOIR_TYPE: 'info_reservoir_type',//库区类型
  INFO_CARGO_CATEGORY: 'info_cargo_category',//货位分类
  INFO_EQUIPMENT_TYPE: 'info_equipment_type',//设备类型
  INFO_PLANTING_WAY: 'info_planting_way',//种植方式
  INFO_PLANTING_AGE: 'info_planting_age',//种植年限
  INFO_TISSUE_METHOD: 'info_tissue_method',//培养方式
  INFO_PROCESS_NAME: 'info_process_name',//培养过程名称
  INFO_STOCK_TYPE: 'info_stock_type',//库存类型
  INFO_CERTIFICATION_TYPE: 'info_certification_type',//认证分类
  INFO_OUTSTOCK_TYPE: 'info_outstock_type',//出库类型
  INFO_MAINTENANCE_TYPE: 'info_maintenance_type',//养护措施
  //=============== 专家 模块 ===============
  ONLINE_KNOWLEDGE_ZSK :'online_konwledge_zsk',//知识库类型
  ONLINE_KNOWLEDGE_TYPE:'online_knowledge_type',//类型
  ONLINE_QUEST_OCCURRENCEAREA: 'online_quest_occurrenceArea',//发生面积
  ONLINE_QUEST_WEATHER: 'online_quest_weather',//天气情况
  ONLINE_EXPERT_INTRODUCTION: 'online_expert_introduction',//专家类型
  AMOUNT_UNIT: 'amount_unit',//数量类型
  FILE_STORE_PATH:'file_store_path',//上传文件
  ZSK_LABEL_FLAG:'zsk_label_flag',
  //=========

  PLATFORM_DATA_FLAG:'platform_data_flag',//平台数据标识
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas(dictType) {
    //库存类型单独写的，涉及代码逻辑中有写死的情况，数据类型,1入库，2.出库，3 全部
  if(dictType == "stock_type_1"){
    var data = []
    data.push({colorType:"primary",cssClass:"",label:"采购入库",value:"1"})
    data.push({colorType:"success",cssClass:"",label:"加工入库",value:"2"})
    data.push({colorType:"default",cssClass:"",label:"退货入库",value:"4"})
    data.push({colorType:"info",cssClass:"",label:"召回入库",value:"5"})
    data.push({colorType:"success",cssClass:"",label:"采收入库",value:"7"})
    data.push({colorType:"info",cssClass:"",label:"组培入库",value:"8"})
    data.push({colorType:"success",cssClass:"",label:"深加工入库",value:"9"})
    return data
  } else if(dictType == "stock_type_2"){
    var data = []
    data.push({colorType:"danger",cssClass:"",label:"销售出库",value:"3"})
    data.push({colorType:"info",cssClass:"",label:"其他出库",value:"6"})
    return data
  }else if(dictType == "stock_type_cjg"){//初加工选择库存物料时，目前仅支持采购来源和种植采收来源的
    var data = []
    data.push({colorType:"primary",cssClass:"",label:"采购入库",value:"1"})
    data.push({colorType:"success",cssClass:"",label:"采收入库",value:"7"})
    return data
  }else if(dictType == "stock_type_seed"){//初加工选择库存物料时，目前仅支持采购来源和种植采收来源的
    var data = []
    data.push({colorType:"primary",cssClass:"",label:"采购入库",value:"1"})
    data.push({colorType:"info",cssClass:"",label:"组培入库",value:"8"})
    return data
  }else if(dictType == "stock_type_sjg"){//深加工选择库存物料时，目前仅支持初加工
    var data = []
    data.push({colorType:"success",cssClass:"",label:"加工入库",value:"2"})
    return data
  }else if(dictType == "stock_type_3"){
    var data = []
    data.push({colorType:"primary",cssClass:"",label:"采购入库",value:"1"})
    data.push({colorType:"success",cssClass:"",label:"加工入库",value:"2"})
    data.push({colorType:"danger",cssClass:"",label:"销售出库",value:"3"})
    data.push({colorType:"default",cssClass:"",label:"退货入库",value:"4"})
    data.push({colorType:"info",cssClass:"",label:"召回入库",value:"5"})
    data.push({colorType:"info",cssClass:"",label:"其他出库",value:"6"})
    data.push({colorType:"success",cssClass:"",label:"采收入库",value:"7"})
    data.push({colorType:"info",cssClass:"",label:"组培入库",value:"8"})
    data.push({colorType:"success",cssClass:"",label:"深加工入库",value:"9"})
    return data
  } else{
    return store.getters.dict_datas[dictType] || []
  }

}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @param values 数组、单个元素
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas2(dictType, values) {
  if (values === undefined) {
    return [];
  }
  // 如果是单个元素，则转换成数组
  if (!Array.isArray(values)) {
    values = [this.value];
  }
  // 获得字典数据
  const results = [];
  for (const value of values) {
    const dict = getDictData(dictType, value);
    if (dict) {
      results.push(dict);
    }
  }
  return results;
}

export function getDictData(dictType, value) {
  // 获取 dictType 对应的数据字典数组
  const dictDatas = getDictDatas(dictType)
  if (!dictDatas || dictDatas.length === 0) {
    return ''
  }
  // 获取 value 对应的展示名
  value = value + '' // 强制转换成字符串，因为 DictData 小类数值，是字符串
  for (const dictData of dictDatas) {
    if (dictData.value === value) {
      return dictData;
    }
  }
  return undefined
}

export function getDictDataLabel(dictType, value) {
  const dict = getDictData(dictType, value);
  return dict ? dict.label : '';
}

