<!--
 * @Author: guojiren 7755@123.com
 * @Date: 2023-01-02 11:38:47
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2023-02-16 17:33:49
 * @FilePath: \xinghe-herbs-trace-admin-ui\src\components\DictTag\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <span>
    <template v-for="(dict, index) in this.getDictDatas2(type, value)">
      <!-- 默认样式 -->
      <span
        v-if="
          dict.colorType === 'default' ||
          dict.colorType === '' ||
          dict.colorType === undefined
        "
        :key="dict.value"
        :index="index"
        :class="dict.cssClass"
        >{{ dict.label }}</span
      >
      <!-- Tag 样式 -->
      <el-tag
        v-else
        :disable-transitions="true"
        :key="dict.value"
        :index="index"
        :type="dict.colorType"
        :class="dict.cssClass"
      >
        {{ dict.label }}
      </el-tag>
    </template>
  </span>
</template>

<script>
export default {
  name: "DictTag",
  props: {
    type: String,
    value: [Number, String, Boolean, Array],
  },
  // mounted() {
  //   console.log(this.getDictDatas2(this.type, this.value), "ssss");
  // },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
